// src/Routes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Common/Navbar";
import Home from "./Components/Common/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Visa from "./pages/Visa";
import Umrah from "./pages/Umrah";
import Tours from "./pages/Tours";
import Contact from "./pages/Contact";

const AppRoutes = () => (
  <>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about-us" element={<About />} />
      <Route path="services" element={<Services />} />
      <Route path="visa" element={<Visa />} />
      <Route path="umrah" element={<Umrah />} />
      <Route path="tours" element={<Tours />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  </>
);

export default AppRoutes;
