import React from "react";
import { IoMdClose } from "react-icons/io";
import logo from "../../Images/Flyday-logo.png";

const Hamburger = ({setClick}) => {
  const heading = [
    {
      name: "HOME",
      url: "/",
    },
    {
      name: "ABOUT US",
      url: "/about",
    },
    {
      name: "SERVICES",
      url: "/services",
    },
    {
      name: "VISA",
      url: "/visa",
    },
    {
      name: "UMRAH",
      url: "/umrah",
    },
    {
      name: "TOURS",
      url: "/tours",
    },
    {
      name: "CONTACT US",
      url: "contact",
    },
  ];

  return (
    <div 
     className="absolute top-0 bg-slate-200 pt-5 h-[100%] w-[100%] z-50 ">
      {/* Logo + Menu close Icon Div  */}
      <div
    // data-aos="fade-right"
      className="flex flex-row justify-around items-center pb-5">
        <img
          src={logo}
          alt="Flyday Travels & Tours Logo"
          style={{ width: "12rem", height: "4rem" }}
        />
        <div className="p-1 rounded-md bg-[#0C659D] hover:bg-red-500 cursor-pointer text-white" >
          <IoMdClose onClick={() => setClick(false)} size={20}/>
        </div>
      </div>
      {/* Headings Div  */}
      <div className="flex flex-col items-start ml-10 gap-4 text-black pt-3 pb-3 ">
            {heading.map((item) => (
                <h1 className="font-bold border-b-2 border-blue-500 hover:text-sky-700">{item.name}</h1>
            ))}
          </div>
    </div>
  );
};

export default Hamburger;
