import React from "react";
import Marquee from "react-fast-marquee";
import img1 from "../../Images/Airlines/01.png";
import img2 from "../../Images/Airlines/02.png";
import img3 from "../../Images/Airlines/03.png";
import img4 from "../../Images/Airlines/04.png";
import img5 from "../../Images/Airlines/05.png";
import img6 from "../../Images/Airlines/06.png";
import img7 from "../../Images/Airlines/07.png";
import img8 from "../../Images/Airlines/08.png";
import img9 from "../../Images/Airlines/09.png";
import img10 from "../../Images/Airlines/010.png";
import img11 from "../../Images/Airlines/011.png";
import img12 from "../../Images/Airlines/012.png";
import img13 from "../../Images/Airlines/013.png";
import img14 from "../../Images/Airlines/014.png";
import img15 from "../../Images/Airlines/015.png";
import img16 from "../../Images/Airlines/016.png";
import img17 from "../../Images/Airlines/017.png";
import img18 from "../../Images/Airlines/018.png";

const AutoPlaySlider = () => {
  return (
    <div className="">
    <Marquee direction="right" speed={50} >
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img1} alt="AirBlue Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img2} alt="PIA Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img3} alt="Saudia Airline Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img4} alt="Qatar Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform ">
        <img src={img5} alt="AirSial Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform ">
        <img src={img6} alt="Emirates Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img7} alt="Serene Air Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img8} alt="Fly Jinnah Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img9} alt="British Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img10} alt="Etihad Airline Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img11} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img12} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img13} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img14} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img15} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img16} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img17} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
      <div className="mx-10 hover:scale-125 transition-transform">
        <img src={img18} alt="Thai Airways Logo" className="w-22 h-14 md:w-full md:h-full"/>
      </div>
     </Marquee>
    </div>
  );
};

export default AutoPlaySlider;
