import React, { useEffect, useState } from 'react'
import banner1 from '../../Images/Banner-1.jpg'
import banner2 from '../../Images/Banner-2.jpg'
import banner3 from '../../Images/Banner-3.jpg'
import banner4 from '../../Images/Banner-4.jpg'
import banner5 from '../../Images/Banner-5.jpg'

const Slidebar = () => {
    const [images, setImages] = useState([
        banner1,
        banner2,
        banner3,
        banner4,
        banner5,
      ]);
    
      const [currentIndex, setCurrentIndex] = useState(0);
    
      useEffect(() => {
        // Function to update the current index
        const updateIndex = () => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        };
    
        // Set up an interval to change the index every 3 seconds (adjust as needed)
        const intervalId = setInterval(updateIndex, 3000);
    
        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }, [currentIndex, images.length]);
  return (
    
   <div>

    <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />

   </div> 
  )
}

export default Slidebar