import React from "react";
import { FaRegCopyright } from "react-icons/fa6";

const Copyright = () => {
  return (
    <div className="flex justify-center items-center py-3 px-5">
      <h1>Copyright </h1> <span className="px-2">

      <FaRegCopyright />
      </span>
      <h1>2024 | Fatima Qureshi | All Rights Reserved.</h1>
    </div>
  );
};

export default Copyright;
