import React from "react";
import Navbar from "../Components/Common/Navbar";
import banner from "../Images/About_Us_Banner.png";
import ceo from "../Images/CEO_Image-removebg-preview.png";
import circle from "../Images/toppng.com-white-circle-png-new-moon-phase-drawi-2400x2400.png";
import Footer from "../Components/Common/Footer";
const About = () => {
  return (
    <div>
      {/* About Section  */}
      <div>
        <img src={banner} alt="Banner Image" />
        <h1 class="py-3 text-sky-700 text-center font-bold text-base lg:text-lg xl:text-2xl">
          About Us
        </h1>
        <div class="px-4 md:px-10 lg:px-20 xl:px-40 py-3 text-center">
          <div class="px-2 md:px-3 lg:px-5 xl:px-10 py-2">
            <p class="text-justify py-3 text-sm lg:text-base">
              <b>Flyday Travel & Tours</b> is the epitome of excellence in the
              realm of travel agencies, serving as Rawalpindi's foremost
              destination for bespoke travel experiences. With an unwavering
              commitment to meeting the diverse needs of our clientele, we offer
              an extensive suite of services designed to cater to every aspect
              of the modern traveler's journey. From the initial stages of visa
              consultancy to the final touches of luxurious hotel reservations,
              our meticulous approach ensures that every detail is meticulously
              curated to perfection.
              <br />
              Our range of services includes personalized air ticket bookings,
              meticulously crafted travel insurance packages, and bespoke
              adventure itineraries that promise unforgettable experiences. We
              understand that no two travelers are alike, which is why our team
              goes above and beyond to tailor each journey to the unique
              preferences and requirements of our clients. Whether you're
              seeking an adrenaline-fueled escapade or a tranquil retreat,
              Flyday Travel & Tours is your ultimate partner in realizing your
              travel dreams.
              <br />
              At the heart of our operations lies a commitment to providing
              exceptional value without compromise. We leverage our extensive
              network of partnerships with leading airlines, hotels, and service
              providers to secure competitive rates that guarantee maximum value
              for our clients. Moreover, our dedication to customer satisfaction
              ensures that every interaction with Flyday Travel & Tours is
              characterized by professionalism, reliability, and personalized
              attention.
              <br />
              In addition to our comprehensive range of services, we take
              immense pride in our specialization in Umrah packages. Recognizing
              the profound significance of spiritual journeys, we offer
              meticulously curated packages that encompass every aspect of the
              pilgrimage experience. From facilitating visa applications to
              arranging accommodations in close proximity to holy sites, our
              expert team is dedicated to ensuring a seamless and spiritually
              enriching journey for every pilgrim.
              <br />
              At Flyday Travel & Tours, we believe that travel is not merely
              about reaching a destination but embarking on a transformative
              journey of exploration and discovery. With our unwavering
              commitment to excellence, personalized approach, and attention to
              detail, we invite you to entrust us with your travel aspirations
              and embark on a truly unforgettable adventure.
            </p>
          </div>
        </div>

        <div>
          <h1 class="py-3 text-sky-700 text-center font-bold text-base lg:text-lg xl:text-2xl">
            CEO's Message
          </h1>
          <div class="flex flex-col md:flex-row justify-center items-center px-4 md:px-10 lg:px-20 xl:px-40 py-3">
            <div class="md:w-5/12 md:h-[90vh] relative mb-5 md:mb-0">
              <img
                src={ceo}
                alt="CEO Profile Image"
                className="ml-5 md:ml-20 md:w-[90%]"
              />
              <div className="bg-sky-700 text-white px-5 py-3 rounded-lg text-center absolute bottom-20 w-[220px] h-[70px] ">
                <p className="text-lg">Asif Shahzad Qureshi </p>
                <p className="text-sm">CEO & Founder </p>
              </div>
            </div>
            <div class="md:w-7/12">
              <p class="text-justify mx-3 md:mx-10 py-3 px-3 md:px-10 text-sm lg:text-base tracking-normal">
                Dear Esteemed Travelers, <br /> <br />
                As we embark on a new chapter filled with boundless
                opportunities and thrilling adventures, I am honored to extend a
                heartfelt welcome to each of you. <br /> At Flyday Travel &
                Tours, we believe in the transformative power of travel to
                enrich lives, foster connections, and create lasting memories.
                It is with great pride and passion that our team dedicates
                itself to curating unforgettable experiences that exceed your
                expectations and inspire your wanderlust. <br /> In an
                ever-evolving world, we remain steadfast in our commitment to
                innovation, excellence, and sustainability. Whether you're
                exploring exotic landscapes, immersing yourself in vibrant
                cultures, or simply seeking moments of serenity, we are here to
                ensure your journey is seamless, safe, and truly extraordinary.{" "}
                <br /> As we journey forward together, we pledge to uphold the
                highest standards of integrity, transparency, and customer
                satisfaction. Your trust in us is the foundation of our success,
                and we are deeply grateful for the privilege to serve you.{" "}
                <br /> Thank you for choosing Flyday Travel & Tours as your
                companion in exploration. Here's to the adventures that await
                us! <br />
                <br />
                Warm regards <br />
                Asif Shahzad CEO, <br />
                Flyday Travel & Tours
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
