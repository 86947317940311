// src/Components/Common/Home.js
import React from "react";
import AutoPlaySlider from "../Partials/AutoPlaySlider";
import MainSection from "../Partials/MainSection";
import Slidebar from "../Partials/Slidebar";
import Services from "../Partials/Services";
import WhyUs from "../Partials/WhyUs";
import Footer from "./Footer";
import Copyright from "./Copyright";
import Loader from "../Loader";
import Counter from "../Counter";
import Destinations from "../Partials/Destinations";
import Testimonials from "../Testimonials";

const Home = () => {
  return (
    <div>
      <Loader />
      <Slidebar />
      <AutoPlaySlider />
      <MainSection />
      <Services />
      <Destinations />
      <div className="flex flex-wrap justify-center gap-8 p-8 bg-[#F1F5F9] mb-10">
        <Counter icon="fa-calendar" endValue={2999} text="No. of Bookings" />
        <Counter icon="fa-smile-beam" endValue={3000} text="Happy Customers" />
        <Counter icon="fa-globe" endValue={1500} text="Destinations" />
        <Counter icon="fa-map" endValue={2500} text="Available Packages" />
      </div>
      <WhyUs />
      {/* <Testimonials /> */}
      <Footer />
      <Copyright />
    </div>
  );
};

export default Home;
