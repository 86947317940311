import React, { useEffect, useState } from 'react';
import { FaMapMarkedAlt, FaSmileBeam, FaGlobeAmericas, FaCalendarCheck } from 'react-icons/fa'; // Import icons from react-icons

const icons = {
  'fa-map': <FaMapMarkedAlt />,
  'fa-smile-beam': <FaSmileBeam />,
  'fa-globe': <FaGlobeAmericas />,
  'fa-calendar': <FaCalendarCheck />
};

const Counter = ({ icon, endValue, text }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = 4000;
    const duration = Math.floor(interval / endValue);
    let startValue = 0;

    const counter = setInterval(() => {
      startValue += 1;
      setCount(startValue);
      if (startValue >= endValue) {
        clearInterval(counter);
      }
    }, duration);

    return () => clearInterval(counter);
  }, [endValue]);

  return (
    <div className="w-40 h-[150px] flex flex-col text-center items-center justify-between p-5 bg-[#0C659D] rounded-lg ">
      <div className="text-4xl text-[#F1F5F9]">
        {icons[icon]}
      </div>
      <span className="text-white text-3xl font-semibold">{count.toString().padStart(3, '0')}</span>
      <span className="text-gray-300 text-sm font-normal">{text}</span>
    </div>
  );
};

export default Counter;
